.nav { width: 80%; position: relative; font-size: 14px; letter-spacing: 2.38px;float: right;text-align: right;
  &__Inner { @include outer-container; max-width: 960px; }
  ul { padding: 0; margin: 0 auto; list-style-type: none; text-align: right; display: table;height: 100px;line-height: 100px;float: right;}
  li { display: table-cell; text-align: center; vertical-align: middle; position: relative;
      ul {transition: 300ms ease-in-out; position: absolute;float: left;width: 100%;text-align: center;padding-top: 30px;visibility: hidden;opacity: 0;transition: visibility 0s, opacity 0.5s linear;
        li {display: block;
            a {line-height: 40px; font-size: 0.8em;}
        }
      }
      &:hover {
        ul {visibility: visible;opacity: 1;}
      }
  }
  a { color: $white; font-size: 1em; text-decoration: none; line-height: 80px; margin: 0; display: block; position: relative;transition: 300ms $ease-in-out-quint;padding: 0 20px;text-transform: uppercase;font-weight: 700;
  
    &:after { content: ""; display: block; height: 2px; position: absolute; top: 50%; left: 20px;opacity: 0;right: 100%; background: $white; margin-top: 15px; transition: 300ms $ease-in-out-quint;
    }
    &:hover {color: $white;transition: 300ms $ease-in-out-quint;
      &:after {opacity: 1; right: 20px;}
    }
  }
  &__Social {
    a { color: $social; font-size: 32px; display: inline-block; transition: 200ms;
      &:hover { color: $black; }
      &:after { display: none; }
    }
  }
  .current-menu-item {
    a {
      &:after {opacity: 1;right: 20px;}
    }
  }
  

  &--Footer { @extend .nav; margin-top: 40px;
    a { line-height: 120px; }
  }
}