.defaultSlider { position: relative; height: 600px;position: relative;margin-top: -100px;float: left;width: 100%;margin-bottom: 0 !important;
  &__Slide {background-size: cover;background-repeat: no-repeat;background-position: center center; position: relative;z-index: 1;
    &:after {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0; background: alpha($black,0.6); z-index: -1;}
  }
  &__Content { height: 600px; position: relative; float: left; width: 100%;}
  &__Box { float: left; width: 100%; position: relative;  top: 50%; transform:translateY(-50%);}
  &__Heading { text-align: center; font-size: 80px; line-height: 112px; margin: 0; padding: 0; font-weight: 900; color: $white; position: relative;text-transform: uppercase}
  &__subHeading { text-align: center; font-size: 30px; line-height: 42px; margin: 0; padding: 0; font-weight: 300; color: $white; position: relative;}
}
.classicText {float: left;width: 100%;padding: 50px 0;text-align: left;
  h4 {font-weight: 500;color: $black;text-align: center;}
  p {font-weight: 300; color: darken($gray, 50%);}
  ul {list-style: none;
    li {font-weight: 300; color: darken($gray, 50%);}
  }
}
.aboutPage {
  &__Inner {float: left;width: 60%;margin-left: 20%;margin-right: 20%;
    &--title {font-size: 40px !important;}
  }
  &__Image {float: left;width: 90%;height: 700px; background-size: cover;background-repeat: no-repeat;background-position: center center;margin: 50px 0 60px 0;margin-left: 5%;}
  float: left;width: 100%;margin-top: 170px;
   h1 {font-size: 60px; font-weight: 900;color: $brand;line-height: 1.1;}
  h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;}
  h3 {font-size: 40px; font-weight: 700;color: $brand;line-height: 1.1;}
  h4 {font-size: 30px; font-weight: 500;color: $brand;line-height: 1.1;}
  h5 {font-size: 20px; font-weight: 500;color: $brand;line-height: 1.1;}
  h6 {font-size: 16px; font-size: 700;color: $gray;line-height: 1.1; text-transform: uppercase;}
  li {color: $gray;font-weight: 300;}
  mark {background: alpha($gray-light,0.3);}
  blockquote {background: alpha($gray-light,0.3); font-weight: 300; font-size: 14px; padding: 20px;}
  p {font-size: 16px;font-weight: 300;color: $gray;}
  a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
    &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
    &:hover {color: $black;transition:300ms;
      &:after {opacity: 1;bottom: 0px;transition:300ms;}
    }
  }
}
.standardSize {height: 400px;margin-top: 150px;background-position: initial;}
.standardPage {margin-top: 0px;padding-bottom: 50px;}
.aboutPageBottom {
  &__Inner {float: left;width: 60%;margin-left: 20%;margin-right: 20%;
    &--title {font-size: 40px !important;}
  }
  &__Image {float: left;width: 90%;height: 700px; background-size: cover;background-repeat: no-repeat;background-position: center center;margin: 50px 0 60px 0;margin-left: 5%;}
  float: left;width: 100%;padding: 0px 0px 60px 0;
    h1 {font-size: 60px; font-weight: 900;color: $brand;line-height: 1.1;}
  h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;}
  h3 {font-size: 40px; font-weight: 700;color: $brand;line-height: 1.1;}
  h4 {font-size: 30px; font-weight: 500;color: $brand;line-height: 1.1;}
  h5 {font-size: 20px; font-weight: 500;color: $brand;line-height: 1.1;}
  h6 {font-size: 16px; font-size: 700;color: $gray;line-height: 1.1; text-transform: uppercase;}
  li {color: $gray;font-weight: 300;}
  mark {background: alpha($gray-light,0.3);}
  blockquote {background: alpha($gray-light,0.3); font-weight: 300; font-size: 14px; padding: 20px;}
  p {font-size: 16px;font-weight: 300;color: $gray;}
  a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
    &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
    &:hover {color: $black;transition:300ms;
      &:after {opacity: 1;bottom: 0px;transition:300ms;}
    }
  }
}
.servicesInner {float: left;width: 90%;margin-left: 5%;padding: 0 0 40px 0; height: auto;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;z-index: 1;
  &:after {content: '';position: absolute;top: 0;right: 55%;bottom: 0;left: 0; z-index: -1;}
 &__Content {float: left;width: 30%;position: relative;
  h2 {text-transform: uppercase;font-size: 60px; color: $gray-light; margin: 0;line-height: 60px; letter-spacing: 2px;position: relative;z-index: 1;margin-bottom: 100px;margin-top: 30px;font-weight: 900;
    span {color: $brand;clear: both; display: block;font-size: 60px;}
    &:after {content: ''; position: absolute;height: 200px; width: 380px; border: 12px solid alpha($brand,0.1); z-index: -1; left: 50px;top: -30px;}
    }
    p {font-size: 18px;color: $gray-light;font-weight: 300;}
 }
 &__Wrap {float: right;height: 660px; width: 65%; margin-left: 2.5%; background: transparent;}
 &__Box {float: left;width: 25%;height: 660px; background-size: cover;background-repeat: no-repeat; background-position: center center;position: relative; z-index: 1;transition: 300ms $ease-in-out-quint;
    &:after {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0; background: alpha($brand,0.97); z-index: -1;transition: 300ms $ease-in-out-quint;}
    &--wrap {float: left;width: 100%;height: 60px;position: relative;}
    &--wrap2 {float: left;width: 100%;height: 100px;position: relative;}
    h4 {font-size: 16px; color: $white;margin: 0;transition: 300ms $ease-in-out-quint;font-weight: 300;float: left;width: 90%;margin-left: 5%;margin-right: 5%;top: 50%;transform:translateY(-50%);position: relative;}
    h3 {color: $white; font-size: 30px; line-height: 40px;transition: 300ms $ease-in-out-quint; text-transform: uppercase;margin: 0;float: left;width: 90%;margin-left: 5%;margin-right: 5%;top: 50%;transform:translateY(-50%);position: relative;}
    i {color: $white;}
    a {float: left;width: 100%;height: 660px;text-decoration: none;position: relative; padding: 0 20px;transition: 300ms $ease-in-out-quint;text-align: center;}
   &--wrapOut {position: relative;float: left;width: 100%; height: 330px;transition: 300ms $ease-in-out-quint;}
   &--wrapInner {position: relative;float: left;width: 100%; top: 50%;transform:translateY(-50%);transition: 300ms $ease-in-out-quint;}
   &:hover {transition: 300ms $ease-in-out-quint;
    a {transition: 300ms $ease-in-out-quint;}
    h4 {color: $white;transition: 300ms $ease-in-out-quint;}
    h3 {color: $white;transition: 300ms $ease-in-out-quint;}
    i { color: $white;}
    .servicesInner__Box--wrapInner {top: 70%;transition: 300ms $ease-in-out-quint;}
    .customTop {top: 30% !important;transition: 300ms $ease-in-out-quint;
        i {transform:rotate(180deg);}
    }
    &:after {transition: 300ms $ease-in-out-quint; background: alpha($brand,0.5);}
   }
 }
}
.addPaddings {padding-bottom: 60px;
    .advice__Box {margin-bottom: 60px;}
}
.addvicePaddings {padding-bottom: 0px;
    .advice__Box {margin-bottom: 0px;}
}
.blockFull {
  .servicesInner__Box {width: 33.33%;}
}

.menuOf {float: left;width: 100%;margin-top: 100px;position: fixed;background: $white;height: 80px;transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);z-index: 10;
  ul {padding: 0;margin: 0;float: left;width: 100%;text-align: center;
    li {list-style: none;display: inline-block;
      a { color: $brand; font-size: 14px; text-decoration: none; line-height: 80px; margin: 0; display: block; position: relative;transition: 300ms $ease-in-out-quint;padding: 0 10px;text-transform: uppercase;font-weight: 700;
  
    &:after { content: ""; display: block; height: 2px; position: absolute; top: 50%; left: 10px;opacity: 0;right: 100%; background: $brand; margin-top: 15px; transition: 300ms $ease-in-out-quint;
    }
    &:hover {color: $brand;transition: 300ms $ease-in-out-quint;
      &:after {opacity: 1; right: 10px;}
    }
  }
    }
  }
}
.menuOf--sticky {margin-top: 100px;position: fixed;}
.offerMargin {margin-top: 0;
  &--0 {margin-top: 190px;}
}
.lb-details {display: none;}
.singleMargins {background-color: transparent !important; margin: 0 !important;padding: 20px 0 !important;}
.teamPage {
  float: left;width: 100%;padding: 150px 0;text-align: center;background: $white;position: relative;
  h3 {font-family: $title;font-size:50px;color: $brand; text-transform: uppercase;margin-top: 0px;}
  h2 {font-size: 20px;margin-top: 0; color: $brand;font-weight: 300;margin-bottom: 70px;}
  h1 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;text-align: left;}
  &__Box {@include span-columns(3);height: 450px;
    &:nth-of-type(4n) {margin-right: 0}
    &--image {float: left;width: 100%;height: 290px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
    &--name {float: left;width: 100%;height: 160px;text-align: center;
      h3 {font-weight: 700;color: $brand;font-size: 18px;margin-top: 20px;margin-bottom: 0;}
      h4 {font-weight: 300;color: $gray-light;font-size: 15px;margin-top: 0;}
      a {text-decoration: none;color: $black;font-size: 13px;
        &:hover {color: $brand;}
      }
    }
  }
}
.singleTeam {float: left;width: 100%;padding: 200px 0 80px 0;
  &__Left {background-size:cover;background-repeat: no-repeat;background-position: center center; width: 38%; margin-right: 2%;height: 450px;float: left;}
  &__Right {
    min-height: 450px;width: 60%;float: left;
     h1 {font-size: 60px; font-weight: 900;color: $brand;line-height: 1.1;}
  h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;}
  h3 {font-size: 40px; font-weight: 700;color: $brand;line-height: 1.1;margin-top: 0;margin-bottom: 0;}
  h4 {font-size: 30px; font-weight: 500;color: $brand;line-height: 1.1;}
  h5 {font-size: 20px; font-weight: 500;color: $gray;line-height: 1.1;margin-top: 15px;}
  h6 {font-size: 16px; font-size: 700;color: $gray;line-height: 1.1; text-transform: uppercase;}
  li {color: $gray;font-weight: 300;}
  mark {background: alpha($gray-light,0.3);}
  blockquote {background: alpha($gray-light,0.3); font-weight: 300; font-size: 14px; padding: 20px;}
  p {font-size: 16px;font-weight: 300;color: $gray;}
  a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
    &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
    &:hover {color: $black;transition:300ms;
      &:after {opacity: 1;bottom: 0px;transition:300ms;}
    }
  }
  }
}