// BOWER
@import "../../assets/bower_components/normalize.css/normalize.css";

// BOURBON/NEAT
@import "bourbon";
@import "neat";

// CORE
@import "core/grid-settings";
@import "core/defaults";
@import "core/mixins";

// MODULES
// @import "modules/sprite";
@import "modules/template"; // Main template file
@import "modules/home"; // Main template file
@import "modules/about";
@import "modules/contact";

// PARTIALS
@import "partials/header";
@import "partials/footer";
@import "partials/nav";


@import "modules/mobile";