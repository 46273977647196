.siteHeader { color: $white;position: absolute;width: 100%;z-index: 100;background-color: transparent;height: 100px;font-family: $title;
  &__Logo {float: left;width: 20%;padding-left: 1%;
    .standardLogo {display: none;}
    .whiteLogo {display: block;}
    a {
      line-height: 100px;height: 100px;text-decoration: none; color: $black; font-size: 20px; font-weight: 700; text-transform: uppercase;
      img {vertical-align: middle;max-height: 40px;}
    }
  }
  &__Search {float: right;width: 20%;text-align: right;
    a {
      line-height: 100px;height: 100px;text-decoration: none; color: $black; font-size: 20px; font-weight: 700; text-transform: uppercase;
    }
  }
}

.js-SiteHeader {
    &--is-Active {background:$white;opacity: 1;visibility: visible;width: 100%;box-shadow: 0 5px 5px -5px alpha($black, 0.1);margin-top: 130px;
    .standardLogo {display: block;}
    .whiteLogo {display: none;}
    .nav {
      a {color: $brand !important;
        &:after {background: $brand;}
      }
    }
    }
    &--is-Fixed {background: $white;position: fixed;left: 0px;width: 100%;-webkit-transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);top: -130px;
    .standardLogo {display: block;}
    .whiteLogo {display: none;}
    .nav {
      a {color: $brand !important;
        &:after {background: $brand;}
      }
    }
    }
}

.js-SiteHeaderAll {
    &--is-Active {background:$white;opacity: 1;visibility: visible;width: 100%;box-shadow: 0 5px 5px -5px alpha($black, 0.1);margin-top: 130px;
    .standardLogo {display: block;}
    .whiteLogo {display: none;}
    .nav {
      a {color: $brand !important;
        &:after {background: $brand;}
      }
    }
    }
    &--is-Fixed {background: $white;position: fixed;left: 0px;width: 100%;-webkit-transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);top: -130px;
    .standardLogo {display: block;}
    .whiteLogo {display: none;}
    .nav {
      a {color: $brand !important;
        &:after {background: $brand;}
      }
    }
    }
}