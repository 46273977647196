/* ============================================================
  PULSE
============================================================ */


@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
.button {bottom: 100px;position: absolute;left: 0;right: 0;
      a {text-decoration: none; height: 40px;width: 100%;text-align: center;float: left;outline:none;-webkit-animation: pulse 1s ease infinite;
  -moz-animation: pulse 1s ease infinite;
  -ms-animation: pulse 1s ease infinite;
  -o-animation: pulse 1s ease infinite;
  animation: pulse 1s ease infinite;
        img {max-height: 40px;display: inline;}
      }
    }
    .mobile {display: none;}
.homeSlider { float: left; width: 100%;overflow: hidden; margin-top: -100px; margin-bottom: 0 !important;
  &__Slide {background-size: cover; background-repeat: no-repeat; background-position: center center;height: 850px; position: relative; z-index: 1;
      &:after {content: '';position: absolute;top: 0;right: 55%;bottom: 0;left: 0; background: alpha($brand,0.9); z-index: -1;}
      &:before {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 45%; background: alpha($black,0.1); z-index: -1;}
  } 
  &__Inner {text-align: left; height: 850px;
    &--imgWrap {position: relative; width: 100%; float: left; top: 55%; transform: translateY(-50%); display: inline-block; padding: 10px 30px;
      h4 {color: $white; font-weight: 300; font-size: 36px;width: 100%;text-align: left; transition: all .8s ease-in-out; transform:translateY(-50%); opacity: 0; width: 70%;margin-top: 0;}
      h2 {color: $white; font-weight: 700;font-size: 90px;margin: 0; width: 100%;text-align: left;line-height: 1; text-transform: uppercase;transition: all .8s ease-in-out; transform:translateY(-50%); opacity: 0;}
      a {@include moreButton($white, transparent); color: $white;overflow: hidden;
        &:hover {color: $brand;}
      }
    }
  }
  .chooseLang {float: right; padding-right: 1%; padding-bottom: 1%;
    ul {padding: 0;margin: 0;
      li { list-style: none;display: inline-block; position: relative; z-index: 1;
        &:after {content: '/'; position: absolute;bottom: 0;right: -6px; color: $white; z-index: -1;display: block; font-size: 12px;}
        &:last-of-type {
          &:after {display: none;}
        }
        a {color: $white;text-decoration: none;font-size: 12px;padding: 0 5px;}
      }
    }
  }
  .slick-active {
    h2 {transform:translateY(0); opacity: 1;}
    h4 {transform:translateY(0); opacity: 1;}
  }
  .slick-dots { bottom: 0; left: 40%;width: auto;
    li {width: 10px;
      button {
        &:before { font-size: 10px;color: $white; opacity: 0.5; }
      }
    }
    li.slick-active {
      button {
        &:before { color: $white; opacity: 1; }
      }
    }
  }
}

.arrowPrev, .arrowNext {display: block;position: absolute;top: 0;bottom: 0;width: 60px;height: 100%;z-index: 10000;}
.arrowPrev {height: 100vh;line-height: 100vh; opacity:1; transition:200ms;color: $white;font-size: 40px;padding-left: 15px;
  &:after {width: 50px;bottom: 45%;top: 45%;left: 0;position: absolute;content: '';}
  &:hover {opacity:0.8; transition:200ms;}
  &:before {line-height: 100vh;font-size: 26px !important;}
}

.arrowNext {height: 100vh;line-height: 100vh;transition:200ms;color: $white;font-size: 40px; opacity:1; transition:200ms;right: 0;text-align: right;padding-right: 15px;
  &:after {width: 50px;bottom: 45%;top: 45%;right: 0;position: absolute;content: '';}
  &:hover {opacity:0.8; transition:200ms;}
  &:before {line-height: 100vh;font-size: 26px !important;}
}
@keyframes fadeVideo {
  0%, 50% { opacity: 0; }
  100% { opacity: 1; }
}
.intro { position: relative; @include overlay(0.6); overflow: hidden; z-index: -1;height: 100vh; margin-top: -100px;float: left;width: 100%;
  &__Content { @include center; z-index: 10; max-width: $max-width; width: 100%; text-align: center;}
  &__Title { color: $white; font-size: 60px;margin: 0; line-height: 1;  text-transform: uppercase; font-weight: 900;text-align: center; letter-spacing: 1px;
  }
  &__Lead { font-size: 30px; margin: 0 auto; color: $white; font-weight: 300; max-width: 600px; text-transform: uppercase;}
  &__Video { @include center(x); position: fixed; width: 100%; height: auto; top: 0; z-index: -2; animation: 3s fadeVideo; }
}
.chooseLang {float: right; padding-right: 1%; padding-bottom: 1%;bottom: 0;position: absolute; float: right;right: 0;
    ul {padding: 0;margin: 0;
      li { list-style: none;display: inline-block; position: relative; z-index: 1;
        &:after {content: '/'; position: absolute;bottom: 0;right: -6px; color: $white; z-index: -1;display: block; font-size: 12px;}
        &:last-of-type {
          &:after {display: none;}
        }
        a {color: $white;text-decoration: none;font-size: 12px;padding: 0 5px;}
      }
    }
  }
.aboutUs {
  float: left;width: 100%;background: $white;padding: 70px 0;
  &__Content {float: left;width: 55%;position: relative;z-index: 10;padding-right: 20px;
  h2 {text-transform: uppercase;font-size: 60px; color: $black; margin: 0;line-height: 60px; letter-spacing: 2px;position: relative;z-index: 1;margin-bottom: 100px;margin-top: 30px;font-weight: 900;
    span {color: $brand;clear: both; display: block;font-size: 60px;}
    &:after {content: ''; position: absolute;height: 200px; width: 380px; border: 12px solid alpha($gray,0.05); z-index: -1; left: 50px;top: -30px;}
    }
    p {font-size: 18px;color: $black;font-weight: 300;}
  }
  &__Slider {float: left;width: 45%;height: 600px;position: relative;z-index: 1;
  
    

    .slick-dots { bottom: 0; 
    li {width: 10px;
      button {
        &:before { font-size: 10px;color: $white; opacity: 0.5; }
      }
    }
    li.slick-active {
      button {
        &:before { color: $white; opacity: 1; }
      }
    }
  }
  }
  &__Image {float: left;width: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;
    height: 600px !important;position: relative;z-index: 1;
  }
}
.services {float: left;width: 100%;padding: 70px; height: 800px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;z-index: 1;
  &:after {content: '';position: absolute;top: 0;right: 55%;bottom: 0;left: 0; background: alpha($brand,0.6); z-index: -1;}
 &__Content {float: left;width: 30%;position: relative;
  h2 {text-transform: uppercase;font-size: 60px; color: $white; margin: 0;line-height: 60px; letter-spacing: 2px;position: relative;z-index: 1;margin-bottom: 100px;margin-top: 30px;font-weight: 900;
    span {color: $brand;clear: both; display: block;font-size: 60px;}
    &:after {content: ''; position: absolute;height: 200px; width: 380px; border: 12px solid alpha($white,0.1); z-index: -1; left: 50px;top: -30px;}
    }
    p {font-size: 18px;color: $white;font-weight: 300;}
 }
 &__Wrap {float: right;height: 660px; width: 65%; margin-right: 2.5%;margin-left: 2.5%; background: transparent;}
 &__Box {float: left;width: 25%;height: 660px; background-size: cover;background-repeat: no-repeat; background-position: center center;position: relative; z-index: 1;transition: 300ms $ease-in-out-quint;
    &:after {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0; background: alpha($brand,0.97); z-index: -1;transition: 300ms $ease-in-out-quint;}
    &--wrap {float: left;width: 100%;height: 60px;position: relative;}
    &--wrap2 {float: left;width: 100%;height: 100px;position: relative;}
    h4 {font-size: 16px; color: $white;margin: 0;transition: 300ms $ease-in-out-quint;font-weight: 300;float: left;width: 90%;margin-left: 5%;margin-right: 5%;top: 50%;transform:translateY(-50%);position: relative;}
    h3 {color: $white; font-size: 30px; line-height: 40px;transition: 300ms $ease-in-out-quint; text-transform: uppercase;margin: 0;float: left;width: 90%;margin-left: 5%;margin-right: 5%;top: 50%;transform:translateY(-50%);position: relative;}
    i {color: $white;}
    a {float: left;width: 100%;height: 660px;text-decoration: none;position: relative; padding: 0 20px;transition: 300ms $ease-in-out-quint;text-align: center;}
   &--wrapOut {position: relative;float: left;width: 100%; height: 330px;transition: 300ms $ease-in-out-quint;}
   &--wrapInner {position: relative;float: left;width: 100%; top: 50%;transform:translateY(-50%);transition: 300ms $ease-in-out-quint;}
   &:hover {transition: 300ms $ease-in-out-quint;
    a {transition: 300ms $ease-in-out-quint;}
    h4 {color: $white;transition: 300ms $ease-in-out-quint;}
    h3 {color: $white;transition: 300ms $ease-in-out-quint;}
    i { color: $white;}
    .services__Box--wrapInner {top: 70%;transition: 300ms $ease-in-out-quint;}
    .customTop {top: 30% !important;transition: 300ms $ease-in-out-quint;
        i {transform:rotate(180deg);}
    }
    &:after {transition: 300ms $ease-in-out-quint; background: alpha($brand,0.5);}
   }
 }
}

.smallTop {margin-top: 30px !important; margin-bottom: 100px !important;}
.advice {float: left;width: 100%; background-color: $white;text-align: center;
  h3 {font-family: $title;font-size:50px;color: $brand;margin-bottom: 0; text-transform: uppercase;margin-top: 100px;}
  h2 {font-size: 20px;margin-top: 0; color: $brand;font-weight: 300;}
  &__More {@include moreButton($brand, transparent); color: $brand;overflow: hidden; margin-top: 70px;margin-bottom: 30px;
        &:before {background: $brand;}
        &:hover {color: $white;
          
        }
      }
  &__Box {float: left;width: 25%; height: 150px; background: $brand;margin-top: 30px;
    h4 {margin: 0; text-transform: uppercase;color: $white;}
    p {margin: 0; font-style: italic;color: $white;}
    &--wrap {position: relative; top: 50%;transform:translateY(-50%);}
    a {text-decoration: none;color: $white;}
  }
  &__Inner {
      a {font-size: 20px;margin-top: 0; color: $brand;font-weight: 300;text-decoration: none;transition:300ms;
        &:hover {opacity: 0.8;}
      }
      p {font-size: 20px;margin-top: 0; color: $brand;font-weight: 300;}
    }
}

.news {float: left;width: 100%;padding: 70px; height: 800px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;z-index: 1;
  &:after {content: '';position: absolute;top: 0;right: 55%;bottom: 0;left: 0; background: alpha($brand,0.6); z-index: -1;}
 &__Content {float: left;width: 30%;position: relative;
  h2 {text-transform: uppercase;font-size: 60px; color: $white; margin: 0;line-height: 60px; letter-spacing: 2px;position: relative;z-index: 1;margin-bottom: 100px;margin-top: 30px;font-weight: 900;
    span {color: $brand;clear: both; display: block;font-size: 60px;}
    &:after {content: ''; position: absolute;height: 200px; width: 380px; border: 12px solid alpha($white,0.1); z-index: -1; left: 50px;top: -30px;}
    }
    p {font-size: 18px;color: $white;font-weight: 300;}
 }
 &__Wrap {float: right;height: 660px; width: 65%; margin-right: 2.5%;margin-left: 2.5%; background: $white;}
 &__Box {float: left;width: 100%;height: 165px; background-size: cover;background-repeat: no-repeat; background-position: center center;position: relative; z-index: 1;transition: 300ms $ease-in-out-quint;
    &:after {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0; background: alpha($white,0.95); z-index: -1;transition: 300ms $ease-in-out-quint;}
    h4 {font-size: 16px; color: $gray-light;margin: 0;transition: 300ms $ease-in-out-quint;font-weight: 300;}
    h3 {color: $brand; font-size: 20px;margin: 0;transition: 300ms $ease-in-out-quint;}
    i {color: $brand;}
    a {float: left;width: 100%;height: 165px;text-decoration: none;position: relative; padding: 0 50px;transition: 300ms $ease-in-out-quint;}
   &--wrapText {position: relative;top: 50%;transform:translateY(-50%);float: left;width: 50%;}
   &--wrapRight {position: relative;top: 50%;transform:translateY(-50%);float: left;width: 50%;text-align: right;}
   &:hover {transition: 300ms $ease-in-out-quint;
    a {padding-left: 150px;padding-right: 150px;transition: 300ms $ease-in-out-quint;}
    h4 {color: $white;transition: 300ms $ease-in-out-quint;}
    h3 {color: $white;transition: 300ms $ease-in-out-quint;}
    i { color: $white;}
    &:after {transition: 300ms $ease-in-out-quint; background: alpha($brand,0.5);}
   }
 }
}

.partners {float: left;width: 100%; position: relative;z-index: 1;margin-top: 50px;text-align: center;background: $gray;
   h2 {text-decoration: none; color: $brand; font-weight: 700; position: relative; z-index: 1;font-size: 60px;display: inline-block;clear: both;margin: 0; text-align: center;}  
    h4 {font-size: 26px; color: alpha($black,0.3); float: left;width: 100%;text-align: center; font-weight: 300;margin-top: 0;margin-bottom: 40px;}
    &__Slider {float: left;width: 100%; margin-bottom: 0 !important;}
  &__Box {width: 25%;float: left;height: 160px !important;background-size: 140px auto;background-repeat: no-repeat;background-position: center center;}

}
.references {
  float: left;width: 100%;padding: 100px 0;text-align: center;background: $white;position: relative;
  h3 {font-family: $title;font-size:50px;color: $brand;margin-bottom: 0; text-transform: uppercase;margin-top: 0px;}
  h2 {font-size: 20px;margin-top: 0; color: $brand;font-weight: 300;margin-bottom: 70px;}
  &__Box {float: left;width: 20%;height: 400px;
    &--image {float: left;width: 100%;height: 290px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
    &--name {float: left;width: 100%;height: 110px;text-align: center;
      h4 {font-weight: 700;color: $brand;font-size: 18px}
      h5 {font-weight: 300;color: $gray-light;font-size: 15px;}
    }
  }
}

.newsletter {
  float: left;width: 100%;padding: 70px 0;text-align: center;background: $brand;position: relative;
  h3 {font-family: $title;font-size:50px;color: $white;margin-bottom: 0; text-transform: uppercase;margin-top: 0px;}
  h2 {font-size: 20px;margin-top: 0; color: $white;font-weight: 300;margin-bottom: 50px;}
  input {height: 50px;width: 250px;border: 1px solid $white;padding: 10px;background: $white;color: $gray;}
  input[type="submit"] {height: 50px;width: 100px;border: 1px solid $white;padding: 0px;background: $white;color: $brand;margin-left:2px;}
}
.addingToBottom {padding-bottom: 60px;}
 .arrowPrev-reference, .arrowNext-reference {display: block;position: absolute;top: 0;bottom: 0;width: 60px;height: 100%;z-index: 10000;
    &:after {display: none;}
    
  }
.arrowPrev-reference {opacity:1; transition:200ms;color: $white;font-size: 40px;padding-left: 15px;
  &:hover {opacity:0.8; transition:200ms;}
  &:before {line-height: 300px;font-size: 26px !important;}
}

.arrowNext-reference {transition:200ms;color: $white;font-size: 40px; opacity:1; transition:200ms;right: 10px;text-align: right;padding-right: 15px;
  &:hover {opacity:0.8; transition:200ms;}
  &:before {line-height: 300px;font-size: 26px !important;}
}