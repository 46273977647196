.siteFooter { float: left; width: 100%;overflow: hidden;position: relative;
  &__Slide {background-size: cover; background-repeat: no-repeat; background-position: center center;height: 850px; position: relative; z-index: 1;padding: 70px;
      &:after {content: '';position: absolute;top: 0;right: 50%;bottom: 0;left: 0; background: alpha($brand,0.9); z-index: -1;}
      &:before {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 50%; background: alpha($black,0.6); z-index: -1;}
  } 
  &__Left {text-align: left; width: 50%;float: left;height: 710px;
    &--imgWrap {position: relative; width: 100%; float: left;  display: inline-block; padding: 10px 30px;
      h2 {text-transform: uppercase;font-size: 60px; color: $white; margin: 0;line-height: 60px; letter-spacing: 2px;position: relative;z-index: 1;margin-bottom: 100px;margin-top: 30px;font-weight: 900;
    span {color: $white;clear: both; display: block;font-size: 60px;}
    &:after {content: ''; position: absolute;height: 200px; width: 380px; border: 12px solid alpha($white,0.1); z-index: -1; left: 50px;top: -30px;}
    }

    p {font-size: 18px;color: $white;font-weight: 300;width: 80%;}
    ul {padding: 0;margin: 0;
      li {list-style: none;font-size: 18px;color: $white;font-weight: 300;margin-top: 0;
         h3 {font-size: 18px;color: $white;font-weight: 900;text-transform: uppercase;margin-bottom: 0;}
         p {font-size: 18px;color: $white;font-weight: 300;margin-top: 0;}
      }
    }
    ul.socialHome {padding: 0;margin: 0;
        li {list-style: none;display: inline-block;line-height: 100px;text-align: center;
          a {float: left;width: 100%;height: 100%;transition:300ms;color: $white;
            &:hover {transition:300ms;
              i {background: $white;color: $brand;transition:300ms;}
            }
            i {font-size: 20px;border: 1px solid $white;width: 40px;height: 40px;line-height: 40px;transition:300ms;}
          }
        }
      }
    }
  }
  &__Copy {width: 45%;margin-left: 5%; float: left; height: 50px;padding-left: 30px;line-height: 50px;padding-right: 30px;
    &--box {float: left;width: 40%;
      &:last-of-type {
        text-align: right;
      }
      span {color: $white;font-weight: 300;}
      a {text-decoration: none;color: $white; font-weight: 300;}
    }
  }
  &__Right {float: left; width: 45%;margin-left: 5%;height: 660px;
    &--imgWrap {position: relative; width: 100%; float: left; top: 55%; transform: translateY(-50%); display: inline-block; padding: 10px 30px;
      h4 {color: $white; font-weight: 300; font-size: 36px;width: 100%;text-align: left; transition: all .8s ease-in-out; transform:translateY(-50%); opacity: 0; width: 70%;margin-top: 0;}
      h2 {color: $white; font-weight: 700;font-size: 90px;margin: 0; width: 100%;text-align: left;line-height: 1; text-transform: uppercase;transition: all .8s ease-in-out; transform:translateY(-50%); opacity: 0;}
      input[type="submit"] {@include moreButton($white, transparent); color: $white;overflow: hidden;
        &:hover {color: $brand;background: $white;}
      }
      input[type="text"] {height: 50px; width: 80%;border: 0;border-bottom: 2px solid $white;color: $white; background-color: transparent;margin-bottom: 20px;font-weight: 300;font-size: 20px;}
      input[type="email"] {height: 50px; width: 80%;border: 0;border-bottom: 2px solid $white;color: $white; background-color: transparent;margin-bottom: 20px;font-weight: 300;font-size: 20px;}
      textarea {height: 250px; width: 80%;maxwidth: 80%;min-width: 80%;border: 0;border-bottom: 2px solid $white;color: $white; background-color: transparent;margin-bottom: 20px;font-weight: 300;font-size: 20px;}
    

    p {font-size: 18px;color: $white;font-weight: 300;width: 80%;}
    ul {padding: 0;margin: 0;
      li {list-style: none;
         h3 {font-size: 18px;color: $white;font-weight: 900;text-transform: uppercase;margin-bottom: 0;}
         p {font-size: 18px;color: $white;font-weight: 300;margin-top: 0;}
      }
    }
    }
  }
}
div.wpcf7-response-output {color: $white !important;}
.allFooter { float: left; width: 100%;overflow: hidden;position: relative;background: $brand;
  &__Copy {width: 100%;float: left; height: 100px;padding-left: 30px;line-height: 100px;padding-right: 30px;
    &--box {float: left;width: 50%;text-align: center;
      ul {padding: 0;margin: 0;
        li {list-style: none;display: inline-block;line-height: 100px;
          a {float: left;width: 100%;height: 100%;transition:300ms;
            &:hover {transition:300ms;
              i {background: $white;color: $brand;transition:300ms;}
            }
            i {font-size: 20px;border: 1px solid $white;width: 40px;height: 40px;line-height: 40px;transition:300ms;}
          }
        }
      }
      &:first-of-type {
        width: 25%;
        text-align: left;
      }
      &:last-of-type {
        width: 25%;
        text-align: right;
      }
      span {color: $white;font-weight: 300;}
      a {text-decoration: none;color: $white; font-weight: 300;}
    }
  }
}