@media screen and (max-width: 1300px) { 

.aboutUs {
  &__Content {
    h2 {font-size: 48px;
      span {font-size: 48px;}
      &:after {width: 280px;}
    }
  }
  
}
.servicesInner {
  &__Content {
    h2 {font-size: 48px;
      span {font-size: 48px;}
      &:after {width: 280px;}
    }
  }
  &__Box {
    h4 {width: 60%;margin: auto;}
    h3 {font-size: 30px;line-height: 38px;}
  }
 }
.services {
  &__Content {
    h2 {font-size: 48px;
      span {font-size: 48px;}
      &:after {width: 280px;}
    }
  }
  &__Box {
    h4 {width: 60%;margin: auto;}
    h3 {font-size: 30px;line-height: 38px;}
  }
 }
.news {
  &__Content {
    h2 {font-size: 44px;
      span {font-size: 44px;}
      &:after {width: 280px;}
    }
  }
}

 .siteFooter {
  &__Left {
    &--imgWrap {
      h2 {font-size: 44px;
        span {font-size: 44px;}
        &:after {width: 280px;}
      }
    }
  } 
 }



}

.newsPage {
    float: left;;width: 100%; padding: 0 0 10px 0;

  h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;}
    &__More {
      @include moreButton($brand, transparent); color: $brand;overflow: hidden; margin-bottom: 30px;position: relative; left: 50%;transform:translateX(-50%);
        &:before {background: $brand;}
        &:hover {color: $white;
          
        }
    }
    &__Box {
      @include span-columns(4);height: 500px;margin-bottom: 50px;
      &:nth-of-type(3n) {
        margin-right: 0;
      } 
      &:hover {cursor: pointer;
        .newsPage__Box--image {
          height: 500px;transition:300ms;
          &:after {opacity: 1;transition:300ms;}
        }
        .newsPage__Box--content {
          margin-top: -400px;transition:300ms;
        }
      }
      &--image {width: 100%;height: 200px; background-size: cover;background-repeat: no-repeat;background-position: center center;float: left;position: relative;z-index: 1;transition:300ms;

        &:after {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0; background: alpha($white,0.8); z-index: -1;transition: 300ms $ease-in-out-quint;opacity: 0;}
    
      }
      &--content {width: 100%;height: 300px;float: left;z-index: 1;position: relative;transition:300ms;padding: 20px;
        h5 {color: $black;}
        h3 {font-weight: 300;position: relative;z-index: 1;color: $black;
          span {text-transform: uppercase;color: $brand;font-weight: 700;font-size: 24px;}
        }
        p {font-weight: 300; color: darken($gray, 25%);letter-spacing: 0px;font-size: 16px;line-height: 1.5;}
      }
    }
  }

.defineNewHeight {
  height: 450px;
}

.singlePost {
  padding-top: 100px;
  h1 {font-size: 60px; font-weight: 900;color: $brand;line-height: 1.1;}
  h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;}
  h3 {font-size: 40px; font-weight: 700;color: $brand;line-height: 1.1;}
  h4 {font-size: 30px; font-weight: 500;color: $brand;line-height: 1.1;}
  h5 {font-size: 20px; font-weight: 500;color: $brand;line-height: 1.1;}
  h6 {font-size: 16px; font-size: 700;color: $brand;line-height: 1.1;}
  li {color: $gray-light;font-weight: 300;}
  mark {background: alpha($gray-light,0.3);}
  blockquote {background: alpha($gray-light,0.3); font-weight: 300; font-size: 14px; padding: 20px;}
  p {font-size: 16px;font-weight: 300;color: alpha($gray, 0.8);}
  a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
    &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
    &:hover {color: $black;transition:300ms;
      &:after {opacity: 1;bottom: 0px;transition:300ms;}
    }
  }
  .innerSlider {
    float: left;width: 100%;height: 500px;position: relative;
    &__Slide {width: 100%;height: 500px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
  }
  .arrowPrev-single, .arrowNext-single {display: block;position: absolute;top: 0;bottom: 0;width: 60px;height: 100%;z-index: 10000;
    &:after {display: none;}
    
  }
.arrowPrev-single {opacity:1; transition:200ms;color: $white;font-size: 40px;padding-left: 15px;
  &:hover {opacity:0.8; transition:200ms;}
  &:before {line-height: 100vh;font-size: 26px !important;}
}

.arrowNext-single {transition:200ms;color: $white;font-size: 40px; opacity:1; transition:200ms;right: 0;text-align: right;padding-right: 15px;
  &:hover {opacity:0.8; transition:200ms;}
  &:before {line-height: 100vh;font-size: 26px !important;}
}
  .aboutPageBottom {
    padding: 0;padding-bottom: 100px;
    &__Social {float: left;width: 20%;margin: 0;
      ul {padding: 0;margin: 0;width: 100%;text-align: center;padding-top: 50px;
        li {list-style: none;margin-bottom: 10px;
          span {text-transform: uppercase; font-size: 16px; font-weight: 300;}
          a {text-decoration: none;
            &:hover {
              i {color: $black;}
            }
            &:after {display: none;}
            i {color: $gray-light;font-size: 30px;}
          }
        }
      }
    }
    &__Inner {width: 60%;margin-right: 20%;float: left;margin-left: 0;}
  }
}
@media screen and (max-width: 900px) { 
  .mobile {display: block;}
  .intro {height: 550px;margin-top: 0;background-position: center center; background-size: auto 100%; background-repeat: no-repeat;
    &__Content {position: relative;max-width: 90%;margin-left: 5%;margin-right: 5%;top: 50%;left: auto;transform:translate(0);transform:translateY(-50%);}
    &__Video {width: 300%;display: none;}
    .chooseLang {display: none;}
  }
  .aboutUs {
    &__Content {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;
      h2 {width: 100%;text-align: center;margin-bottom: 20px;margin-top: 10px;
        &:after {display: none;}
      }
    }
    &__Slider {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;}
  }
  .services {height: auto;padding: 30px 0;background-size: auto 100%;background-position: top left;
    &:after {right: 0;}
    &__Content {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;
      h2 {width: 100%;text-align: center;margin-bottom: 20px;margin-top: 10px;
        &:after {display: none;}
      }
    }
    &__Wrap {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;height: auto;}
    &__Box {width: 100%;margin-bottom: 5%;height: 330px;text-align: center;overflow: hidden;
      h4 {float: none;}
    }
  }
  .news {height: auto;padding: 30px 0;background-size: auto 100%;background-position: top left;
    &:after {right: 0;}
    &__Content {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;
      h2 {width: 100%;text-align: center;margin-bottom: 20px;margin-top: 10px;
        &:after {display: none;}
      }
    }
    &__Wrap {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;height: auto;background-color: transparent;}
    &__Box {width: 100%;margin-bottom: 5%;height: 330px;text-align: center;
      h4 {float: none;}
      a {height: 330px;}
    }
  }
  .advice {padding-bottom: 10%;width: 90%;margin-left: 5%;margin-right: 5%;
    &__Box {width: 90%;margin-left: 5%;margin-right: 5%;}
  }
  .siteHeader {
    &__Logo {
      a {height: auto;line-height: 1;top: 5px;position: relative;}
    }
  }
  .references {width: 90%;margin-left: 5%;margin-right: 5%;}
  
  .nav{ position: absolute; z-index: 10000000000; float: left; background: $black; @include span-columns(12); right: -100%; transition:500ms; width: 100%;top: 0;bottom: 0;height: 100vh;
  .mobileLogo {display: none;}
  ul { margin: 0; padding: 0; @include clearfix; float: left; width: 100%; text-align: right;background: $brand;position: relative; z-index: 100000000000000; height: 100vh;line-height: initial;} 
  li { @include span-columns(12); line-height: initial; float: none; display: block; height: auto;float: left;width: 100%;  
    a {@include span-columns(12);  background: $brand; text-align: center; text-transform: capitalize; font-family: $default;  font-weight: 700; text-decoration: none;color: $white; text-align: left; text-transform: uppercase; height: auto; padding:5px 15px !important; font-size: 14px !important;line-height: 30px;
      &:hover {color: $white;
        &:before {display: none;}
      }
      &:after {display: none;}
    }
    &:hover {
      a {
        color: $white; font-weight: 700;
      }
      ul {transition: 300ms ease-in-out; position: relative;float: left;width: 100%;text-align: center;padding-top: 0px;background: $brand;margin-left: 0px;border-radius: 0px;padding: 0;padding-left: 20px;transition:300ms;display: block;height: auto;animation:none;visibility: visible !important;opacity: 1 !important;transform: translateY(0);
        li {display: block;text-align: left;border-bottom: 0px solid #f0f0f0;float: left;width: 100%;
          
        }
        li.current-menu-item {
          a {color: $white;}
        }
      }
    }
    ul {transition: 300ms ease-in-out; position: relative;float: left;width: 100%;text-align: center;padding-top: 0px;background: $brand;margin-left: 0px;border-radius: 0px;padding: 0;padding-left: 20px;transition:300ms;display: block;height: auto;visibility: visible !important;opacity: 1 !important;transform: translateY(0);
        li {display: block;text-align: left;border-bottom: 0px solid #f0f0f0;float: left;width: 100%;
          &:last-of-type {border-bottom: 0;}
            a {line-height: 30px; font-size: 14px;color: $white;padding: 0;
              &:hover {color: $brand;}
            }
        }
        li.current-menu-item {
          a {color: $white;}
        }
      }
  }
  .current-menu-item a {color: $white;
    &:before {display: none;}
  }
   .nav__Button {float: left; text-align: right;font-size: 30px !important; top: 0px; position: absolute; padding-right: 10px; right: 110%; z-index: -1; background: $brand; display: block; top: 15px;transition:500ms;
      i {position: absolute; float: left;left: 15%; padding-left: 10px; background: transparent; height: 38px; line-height: 38px; color: $brand; padding-right: 10px;transition:500ms; cursor: pointer; z-index: -1; top: -15px;}
      .fa-close {color: $white; background: $brand;}
      .closeHidden {display: none;transition:500ms;}
      .menuHidden {display: none;transition:500ms;}
    }
  .callNav {width: 40px; height: 40px;  text-align: center; line-height: 40px; color: $white; background: transparent; float: left; display: block;
  i {line-height: 40px; font-size: 24px; height: 40px;width: 40px; transition:500ms; cursor: pointer;}
      .closeHidden {display: none;transition:500ms;}
      .menuHidden {display: none;transition:500ms;}
}


}
.menuActive {right: 0;transition:500ms;width: 70%;
  .nav__Button {right: 115%;transition:500ms;}
 }
 .page-template-page-contact .nav__Button i {color: $white;}
 .home .nav__Button i {color: $white;}
 .js-SiteHeaderAll--is-Fixed .nav a, .js-SiteHeaderAll--is-Fixed .nav--Footer a {
    color: $white !important;
}
body {overflow-x: hidden;}
/* NAVIGATION END */
.siteHeader {
    position: absolute !important;
    width: 100% !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    height: auto !important;
}
.siteFooter {
  &__Slide {height: auto;padding: 30px 0;
    &:before {display: none;}
    &:after {right: 0;}
  }
  &__Left {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;text-align: center;height: auto;
    &--imgWrap {transform: translateY(0%);top: auto;padding: 0;
      h2 {font-size: 30px;
        span {font-size: 30px !important;}
        &:after {display: none;}
      }
      p {width: 100%;}
    }
  }
  &__Right {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;text-align: center;height: auto;
    &--imgWrap {transform: translateY(0%);top: auto;}
  }
  &__Copy {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;height: auto;
    &--box {width: 100%;text-align: center !important;}
  }
}
.aboutPage {margin-top: 70px;
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;}
  &__Image {height: 300px;}
}
.aboutPageBottom {
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;}
}
.servicesInner {height: auto;padding: 30px 0;background-size: auto 100%;background-position: top left;
    &:after {right: 0;}
    &__Content {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;
      h2 {width: 100%;text-align: center;margin-bottom: 20px;margin-top: 10px;
        &:after {display: none;}
      }
    }
    &__Wrap {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;height: auto;}
    &__Box {width: 100%;margin-bottom: 5%;height: 330px;text-align: center;overflow: hidden;
      h4 {float: none;}
    }
  }
  .addPaddings .advice__Box {margin-bottom: 0;}
  .singleMargins {margin-left: 5% !important;margin-right: 5% !important;}
  .menuOf {position: relative !important;margin: 40px 0 !important;height: auto !important;}
  .offerMargin--0 {margin-top: 0px !important;}
  .blockFull .servicesInner__Box {width: 100% !important;}
  .newsPage {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;height: auto;background-color: transparent;
    &:after {right: 0;}
  
    &__Box {width: 100%;margin-bottom: 5%;height: 500px;text-align: center;margin-bottom: 60px;
      h4 {float: none;}
      a {height: 330px;}
      &:hover {cursor: pointer;
        .newsPage__Box--image {
          height: 200px;transition:300ms;
          &:after {display: none;}
        }
        .newsPage__Box--content {
          margin-top: 0px;transition:300ms;
        }
      }
    }
  }
  .defaultSlider{
  &__Content {width: 90%;margin-left: 5%;margin-right: 5%;}
    &__Heading {font-size: 42px;}
}
  .contactPage {
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;
      &--box {width: 80%;margin-left: 10%;margin-right: 10%;
        ul {margin-top: 0 !important;}
      }
  }
}
.profile-image {
    position: relative !important;
    float: left;
    right: auto;
    top: auto;
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.cv-link {
    position: relative;
    right: auto;
    bottom: auto;
    float: left;
    width: 100%;
}
.contactPage__Harmony {height: auto !important;
  li {float: left;width: 100%;}
}
}