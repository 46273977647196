.contactPage {float: left;width: 100%;padding: 60px 0;
  &__Inner {float: left;width: 60%;margin-left: 20%;margin-right: 20%;
    h1 {font-size: 60px; font-weight: 900;color: $brand;line-height: 1.1;}
  h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;}
  h3 {font-size: 40px; font-weight: 700;color: $brand;line-height: 1.1;}
  h4 {font-size: 30px; font-weight: 500;color: $brand;line-height: 1.1;}
  h5 {font-size: 20px; font-weight: 500;color: $brand;line-height: 1.1;}
  h6 {font-size: 16px; font-size: 700;color: $brand;line-height: 1.1;}
  li {color: $gray;font-weight: 300;}
  mark {background: alpha($gray-light,0.3);}
  blockquote {background: alpha($gray-light,0.3); font-weight: 300; font-size: 14px; padding: 20px;}
  p {font-size: 16px;font-weight: 300;color: $gray;}
  a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
    &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
    &:hover {color: $black;transition:300ms;
      &:after {opacity: 1;bottom: 0px;transition:300ms;}
    }
  }
    ul {padding-left: 0;width: 100%;float: left;margin-top: 60px;}
    li {font-weight: 300; color: darken($gray, 15%); text-align: center; list-style: none;
      i {color: $brand;font-size: 44px; margin-bottom: 20px;}
      h3 {font-size: 20px;font-weight: 700;}
      a {text-decoration: none;color: darken($gray, 15%);}
    }
    &--box {width: 33.33%;float: left;}
 &--contactForm {width: 100%;margin: auto;text-align: left;margin-top: 70px;float: left;max-width: 600px;margin-bottom: 150px;
      input[type="submit"] {@include moreButton($brand, transparent); color: $brand;overflow: hidden;float: left;
      &:before {background: $brand;}
        &:hover {color: $white;background: $brand;}
      }
      
       input[type="text"] {height: 50px; width: 100%;border: 0;border-bottom: 2px solid $brand;color: $brand; background-color: transparent;margin-bottom: 20px;font-weight: 300;font-size: 20px;}
       input[type="email"] {height: 50px; width: 100%;border: 0;border-bottom: 2px solid $brand;color: $brand; background-color: transparent;margin-bottom: 20px;font-weight: 300;font-size: 20px;}
      textarea {height: 250px; width: 100%;max-width: 100%;min-width: 100%;border: 0;border-bottom: 2px solid $brand;color: $brand; background-color: transparent;margin-bottom: 20px;font-weight: 300;font-size: 20px;}
 }
  
    
    
  }

  &__Harmony {
    float: left;width: 100%;border-bottom: 1px solid $gray-light;position: relative;height: 200px !important;
    padding: 20px 0;
    &:last-of-type {margin-bottom: 100px;}
    ul {float: left;width: 100%;padding: 0;margin: 0;
      li {font-weight: 300; color: darken($gray, 15%); text-align: center; list-style: none;text-align: left;
        h3 {font-size: 20px; font-weight: 900;color: $brand;line-height: 1.1;}
        p {font-size: 16px;font-weight: 300;color: $gray-light;}
  a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
    &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
    &:hover {color: $black;transition:300ms;
      &:after {opacity: 1;bottom: 0px;transition:300ms;}
    }
  }
      }
    }
  }
}
.profile-image {
    float: right;
    position: absolute;
    height: 100px;
    width: 200px;
    right: 0;
    top: 20px;
}
.cv-link {
    position: absolute;
    right: 0;
    bottom: 20px;
}

.noTop {
  padding-top: 0 !important;margin-top: 0 !important;
}
.noBottom {padding-bottom: 0 !important;background: transparent !important;}
.map {
  float: left;width: 100%; height: 500px;
}